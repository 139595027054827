// init base functions
$(window).on('load', function () {
    classOnBody($('main').attr('id'));
    scrollDirection();
    initGettersAndSetters();
    changeTouchClickText();
});
$(window).on('hashchange', function () {
    initGettersAndSetters();
});

// general input masks
if (is.not.ie()) {
    $('.cepMask').mask("99999-999");
    $('.cpfMask').mask("999.999.999-99");
    $('.dataMask').mask("99/99/9999");
    $('.foneMask').focusout(function () {
        var phone, element;
        element = $(this);
        element.unmask();
        phone = element.val().replace(/\D/g, '');
        if (phone.length > 10) {
            element.mask("(99) 99999-999?9");
        } else {
            element.mask("(99) 9999-9999?9");
        }
    }).trigger('focusout');
}

// init AOS
if (is.not.ie()) {
    AOS.init();
} else {
    $('*').removeAttr("data-aos");
}

// components
if ($('.liSelect').length) {
    $('.liSelect select').on('focusin', function () {
        $(this).parent('.liSelect').addClass('is-active');
    });
    $('.liSelect select').on('focusout', function () {
        $(this).parent('.liSelect').removeClass('is-active');
    });
}

// nav
if ($('.l-nav').length) {
    // vars
    var nav_scrollInit = 0;
    var nav_classActive = 'is-active';
    var nav_el = {
        body: $('body'),
        hamburguer: $('.c-hamburguer'),
        mask: $('.l-nav__maskMenu'),
        nav: $('.l-nav'),
        navContentHero: $('.l-nav__contentHero'),
        liWithOneLevelSubMenu: $('.liWithOneLevelSubMenu > a'),
        liWithTwoLevelsSubMenu: $('.liWithTwoLevelsSubMenu > a'),
        liWithoutSubMenu: $('.liWithoutSubMenu > a'),
        firstSubMenu: $('.firstSubMenu'),
        secondSubMenu: $('.secondSubMenu'),
        openSecondSubMenu: $('.openSecondSubMenu'),
    };

    // functions
    function showMask() {
        nav_el.mask.addClass(nav_classActive);
    }
    function hideMask() {
        nav_el.mask.removeClass(nav_classActive);
    }
    function closeAllSubMenus() {
        nav_el.liWithOneLevelSubMenu.parent('li').removeClass(nav_classActive);
        nav_el.liWithTwoLevelsSubMenu.parent('li').removeClass(nav_classActive);
    }
    function overflowBlocked() {
        nav_el.body.addClass('u-overflowBlocked');
    }
    function overflowFree() {
        nav_el.body.removeClass('u-overflowBlocked');
    }
    function closeNavContent() {
        nav_el.navContentHero.removeClass(nav_classActive);
    }
    function openNavContent() {
        nav_el.navContentHero.addClass(nav_classActive);
    }
    function inactiveHamburguer() {
        nav_el.hamburguer.removeClass(nav_classActive);
    }
    function activeHamburguer() {
        nav_el.hamburguer.addClass(nav_classActive);
    }
    function closeAllMenuItens() {
        hideMask();
        closeAllSubMenus();
        overflowFree();
        closeNavContent();
        inactiveHamburguer();
    }
    function openAllMenuItens() {
        showMask();
        overflowBlocked();
        openNavContent();
        activeHamburguer()
    }

    // effect on scroll
    $(window).on('scroll', function(event) {
        var scrollBody = $(this).scrollTop();

        // scroll up to 99
        if (scrollBody > 99) {
            nav_el.nav.addClass('scrolled');
        } else {
            nav_el.nav.removeClass('scrolled');
        }

        // middle class
        if (scrollBody > 600) {
            nav_el.nav.addClass('hidden');
            nav_el.nav.addClass('scrolledMiddle');
        } else {
            nav_el.nav.removeClass('hidden');
            nav_el.nav.removeClass('scrolledMiddle');
        }

        // scroll up or down
        if (scrollBody < nav_scrollInit) {
            nav_el.nav.removeClass('hidden');
            nav_el.nav.addClass('scrolledUp');
            nav_el.nav.removeClass('scrolledDown');
        } else {
            nav_el.nav.removeClass('scrolledUp');
            nav_el.nav.addClass('scrolledDown');
        }

        // close menus on hidden nav
        if(nav_el.nav.hasClass('hidden')) {
            closeAllMenuItens();
        }

        // reference var
        nav_scrollInit = scrollBody;
    });
    $(window).on('load', function(event) {
        var scrollBody = $(this).scrollTop();
        if (scrollBody > 1) {
            nav_el.nav.addClass('scrolled');
        } else {
            nav_el.nav.removeClass('scrolled');
        }
    });

    // open/close/menus/submenus/hamburguer on clicks
    nav_el.hamburguer.on('click', function() {
        if ($(this).hasClass(nav_classActive)) {
            closeAllMenuItens();
        } else {
            openAllMenuItens();
        }
    });
    nav_el.mask.on('click', function() {
        closeAllMenuItens()
    });
    nav_el.liWithoutSubMenu.on('click', function() {
        closeAllMenuItens();
    });
    nav_el.firstSubMenu.children('li').on('click', function() {
        if(!$(this).hasClass('openSecondSubMenu')) {
            closeAllMenuItens();
        }
    });
    nav_el.secondSubMenu.children('li').on('click', function() {
        closeAllMenuItens();
    });

    // linkScroll
    // if (get__page() === 'home') { Foi migrado para wordepress
    if ($('body').hasClass('home')) {
        $('.js-linkScroll').on('click', function(e) {
            e.preventDefault();
            var finalDestiny = $(this).attr('href');
            if (finalDestiny === '#areas-de-atuacao') {
                goToSection__scroll(finalDestiny, -150, -200, 700, 10);
            } else if (finalDestiny === '#artigos') {
                goToSection__scroll(finalDestiny, -10, -10, 700, 10);
            } else {
                goToSection__scroll(finalDestiny, 100, 50, 700, 10);
            }
        });
        $(window).on('load', function() {
            if (get__hash() === 'noHash') {
                //
            } else {
                setTimeout(function(){
                    switch (window.location.hash) {
                        case '#areas-de-atuacao':
                            goToSection__scroll(get__hash(), -150, -200, 700, 10);
                            break;
                        case '#artigos':
                            goToSection__scroll(get__hash(), -10, -10, 700, 10);
                            break;
                        default:
                            goToSection__scroll(get__hash(), 100, 50, 700, 10);
                    }
                }, 500);
            }
        });
    } else {
        $(".l-nav .js-linkScroll").each(function(index) {
            $(this).removeClass('js-linkScroll');
            var url =  $(this).attr('data-home');
            var hrefLink = url + 'home' + $(this).attr('href');
            console.log(hrefLink);
            $(this).attr('href', hrefLink);
        });
    }
}

// open / close -> modal
function closeModal() {
    //window.location.hash = '';
    $('.u-modal__mask').removeClass('is-active');
    $('.u-modal').removeClass('is-active');
    $('body').removeClass('u-overflowBlocked');
}
$('.u-modal__close').on('click', function() {closeModal()});
$('.u-modal__mask').on('click', function() {closeModal()});

function openModal(modal) {
    window.location.hash = modal;
    $('body').addClass('u-overflowBlocked');
    $(modal).addClass('is-active');
    $('.u-modal__mask').addClass('is-active');
}

function checkHash() {
    switch (window.location.hash) {
        case '#institucional':
        case '#contato':
            openModal(get__hash());
            break;
    }
}

$('.js-openModal').on('click', function(e) {
    e.preventDefault();
    var modalDestiny = $(this).attr('href');
    openModal(modalDestiny);
});

$('.js-openInstitucional').on('click', function(e) {
    checkHash();
});

$(window).on('load', function() {
    checkHash();
});

$(window).on('hashchange', function() {
    checkHash();
});

// modules
if ($('.m_whatsApp').length) {
    var wpp__parts = {
        link_mobile: 'http://api.whatsapp.com/',
        link_web: 'https://web.whatsapp.com/',
        phone: 'send?phone=',
        msg: 'text='
    };
    $('.js-openWhatsModule').on('click', function() {
        var wppNumber = $(this).parent('.m_whatsApp').attr("data-whatsNumber");
        openWhatsApp(wppNumber);
    });
    function openWhatsApp(number) {
        if (is.iphone() || is.androidPhone() || is.windowsPhone() || is.blackberry() || is.mobile()) {
            window.open(wpp__parts.link_mobile + wpp__parts.phone + number, '_blank');
        } else {
            window.open(wpp__parts.link_web + wpp__parts.phone + number, '_blank');
        }
    }
}
if ($('#w_ieDetect').length) {
    function showMessageOnIE(warnLevel) {
        // warnLevel = 1 -> Remover aviso
        // warnLevel = 2 -> Recolher aviso
        // warnLevel = 3 -> Bloquear navegação

        var ieDetectEl = $('#w_ieDetect');
        var ieDetectEl__mask = $('#w_ieDetect__mask');
        var ieDetectEl__unlock = $('.w_ieDetect__unlockSite');

        if (is.ie()) {
            if(warnLevel === 1) {
                $('body').css('overflow', 'hidden');
                ieDetectEl.removeClass('u-dnone').addClass('is-visible');
                ieDetectEl__mask.removeClass('u-dnone').addClass('is-visible');
                ieDetectEl__unlock.on('click', function() {
                    ieDetectEl.remove();
                    ieDetectEl__mask.remove();
                    $('body').css('overflow-y', 'auto');
                    $('body').css('overflow-x', 'hidden');
                });
            }
            if(warnLevel === 2) {
                //block overflow and show modal
                $('body').css('overflow', 'hidden');
                ieDetectEl.removeClass('u-dnone').addClass('is-visible');
                ieDetectEl__mask.removeClass('u-dnone').addClass('is-visible');

                // hide modal and mask
                ieDetectEl__unlock.on('click', function() {
                    $(this).remove();
                    ieDetectEl__mask.remove();
                    ieDetectEl.removeClass('is-visible');
                    ieDetectEl.addClass('is-fixed');
                    $('body').css('overflow-y', 'auto');
                    $('body').css('overflow-x', 'hidden');
                });
            }
            if(warnLevel === 3) {
                $('nav').remove();
                $('header').remove();
                $('footer').remove();
                $('main').remove();
                ieDetectEl__unlock.remove();
                $('body').css('overflow', 'hidden');
                ieDetectEl.removeClass('u-dnone').addClass('is-visible');
                ieDetectEl__mask.removeClass('u-dnone').addClass('is-visible');
            }
        } else {
            ieDetectEl.remove();
            ieDetectEl__mask.remove();
        }
    }
    $(window).on('load', showMessageOnIE(3));
}
if ($('.auxElements__grid').length) {
    $('.auxElements__controls .toggleGrid').on('click', function() {
        $('.auxElements__controls .toggleGrid').toggleClass('is-active');
        $('.auxElements__grid').toggleClass('u-dnone').toggleClass('is-active');
        $('.auxElements__grid .line').toggleClass('is-active');
    });
}
if ($('.auxElements__controls').length) {
    function identifyScroll() {
        returnScroll = 1;
        if ($('body').hasClass('scrolledDown')) {
            returnScroll = 'Down';
        } else if($('body').hasClass('scrolledUp')) {
            returnScroll = 'Up';
        } else {
            returnScroll = 'Static';
        }
        return returnScroll;
    }
    function tasksAuxVars() {
        $('.auxElements__controls .scrollEfetuado').html(Math.round($(window).scrollTop()));
        $('.auxElements__controls .scrollDirection').html(identifyScroll());
        $('.auxElements__controls .viewportWidth').html(Math.round($(window).width()));
        $('.auxElements__controls .viewportHeight').html(Math.round($(window).height()));
        $('.auxElements__controls .navHeight').html(Math.round(get__navHeight()));
    }

    $(document).on('ready', function () {
        tasksAuxVars();
    });
    $(window).on('load', function () {
        tasksAuxVars();
    });
    $(window).on('resize', function () {
        tasksAuxVars();
    });
    $(window).on('scroll', function () {
        tasksAuxVars();
    });
}
if ($('.s-contato').length) {
    $("#formContato").validate({
        submitHandler: function (form) {
            //SUBMIT form
            $(form).ajaxSubmit({
                //target: 'status-envio',
                beforeSubmit: function () {
                    $('#formContato .c-stsSend').removeClass('u-dnone');
                    $('#formContato .c-stsSend__feedback--load').removeClass('u-dnone');
                    $('#formContato  #btnContato').attr('disabled', true);
                },
                success: function (result) {
                    result = result.split('|');
                    if(parseInt(result[1]) == 1){
                        $('#formContato .c-stsSend__feedback').addClass('u-dnone');
                        $('#formContato .c-stsSend__feedback--success').removeClass('u-dnone');
                        setTimeout(function(){
                            $('#formContato #btnContato').attr('disabled',false);
                            $("#formContato .field").val('');
                            $("#formContato textarea").val('').html('');
                            $("#formContato .field").removeClass('error');
                            $('#formContato .c-stsSend').addClass('u-dnone');
                            $('#formContato .c-stsSend__feedback').addClass('u-dnone');
                        },2000);
                    }else{
                        $('#formContato .c-stsSend__feedback').addClass('u-dnone');
                        $('#formContato .c-stsSend__feedback--error').removeClass('u-dnone');
                        $('#formContato .c-stsSend__feedback--error span').html(result[2]);
                        setTimeout(function () {
                            $('#formContato .c-stsSend').addClass('u-dnone');
                            $('#formContato .c-stsSend__feedback').addClass('u-dnone');
                            $('#formContato #btnContato').attr('disabled', false);
                        }, 3000);
                    }
                }
            });
        }
    });
    $(document).on('change','#formContato_estado',function(){
        var uf = $(this).val();
        var uri = $('body').attr('data-dir');
        $("#formContato_cidade").html('<option>Carregando cidades...</option>');
        $.ajax({
            type: "POST",
            data: {uf: uf},
            url: uri + "ajax-cidades.php",
            success: function (result) {
                $("#formContato_cidade").html(result);
            }
        });
    });
}
var i=1;
$(".blocks-gallery-grid").each(function(index) {
    $(this).find('a').attr('data-fancybox','gallery-'+i);
    i++;
});